import { useEffect, useRef, useState } from 'react';

import HeaderMainPartDesktop from './HeaderMainPartDesktop';
import HeaderMainPartMobile from './HeaderMainPartMobile';

const FIXED_HEIGHT = 96;

const HeaderMainPart = ({ matches }: { matches: boolean }) => {
    const mobileHeaderRef = useRef<HTMLDivElement>(null);

    const [isSearchFixed, setIsSearchFixed] = useState(false);

    useEffect(() => {
        let observer: IntersectionObserver;

        observer = new IntersectionObserver(([entry]) => {
            setIsSearchFixed(!entry.isIntersecting);
        });

        if (mobileHeaderRef?.current) {
            observer.observe(mobileHeaderRef.current);
        }

        return () => {
            if (observer) {
                observer.disconnect();
            }
        };
    }, []);

    return (
        <>
            <div
                id="HeaderMainPart"
                ref={mobileHeaderRef}
                css={{
                    height: matches ? FIXED_HEIGHT : 0,
                    minHeight: matches ? FIXED_HEIGHT : 0,
                }}
            />

            {matches ? (
                <div css={{ minHeight: '56px' }} id="HeaderMainPartMobile">
                    <HeaderMainPartMobile isSearchFixed={isSearchFixed} />
                </div>
            ) : (
                <HeaderMainPartDesktop isSearchFixed={isSearchFixed} />
            )}
        </>
    );
};

export default HeaderMainPart;
