import Link from 'next/link';

import { scale } from '@scripts/gds';

import SocTgIcon from '@icons/24/tg.svg';
import SocVkIcon from '@icons/24/vk.svg';

const SocialMediaBlock = () => (
    <>
        <Link target="_blank" aria-label="vk" href="https://vk.com/secumarket" prefetch={false}>
            <SocVkIcon css={{ marginRight: scale(2) }} />
        </Link>

        <Link target="_blank" aria-label="telegram" href="https://t.me/secumarketru" prefetch={false}>
            <SocTgIcon />
        </Link>
    </>
);

export default SocialMediaBlock;
