import { useMutation, useQuery } from 'react-query';

import { ChangePasswordData, CurrentUser } from '@api/auth/types';
import { CommonResponse, FetchError } from '@api/common/types';

import { apiClient } from '..';

const AUTH_URL = 'auth';
export const CURRENT_USER_QUERY_KEY = 'current-user';

export const useCurrentUser = (enabled = true) =>
    useQuery<CommonResponse<CurrentUser>, FetchError>({
        queryKey: CURRENT_USER_QUERY_KEY,
        queryFn: () => apiClient.get(`${AUTH_URL}/current-user`),
        enabled,
    });

export const useChangePassword = () =>
    useMutation<CommonResponse<null>, FetchError, ChangePasswordData>(data =>
        apiClient.post(`${AUTH_URL}/password-change`, { data })
    );

export const useChangePasswordByEmail = () =>
    useMutation<CommonResponse<null>, FetchError, { token: string; password: string }>(data =>
        apiClient.post(`${AUTH_URL}/password-change-public`, { data })
    );

export const useGetSmsCode = () =>
    useMutation<CommonResponse<null>, FetchError, { phone: string }>(data =>
        apiClient.post(`communication/sms:request-code`, { data })
    );

export const useVerificationCodeByPhone = () =>
    useMutation<CommonResponse<null>, FetchError, { phone: string }>(data =>
        apiClient.post(`${AUTH_URL}/phone:verification-code`, { data })
    );

export const useVerificationCodeByEmail = () =>
    useMutation<CommonResponse<null>, FetchError, { email: string }>(data =>
        apiClient.post(`${AUTH_URL}/email:verification-code`, { data })
    );

export const useCheckoutByPhone = () =>
    useMutation<CommonResponse<{ found: boolean }>, FetchError, { phone: string }>(data =>
        apiClient.post(`${AUTH_URL}/checkout:by-phone`, { data })
    );

export const useCheckoutByEmail = () =>
    useMutation<CommonResponse<{ found: boolean }>, FetchError, { email: string }>(data =>
        apiClient.post(`${AUTH_URL}/checkout:by-email`, { data })
    );
