import { CatalogPopupTitleProps } from '@api/catalog';

import { colors, scale } from '@scripts/gds';

import ArrowLeftIcon from '@icons/24/arrow-left.svg';
import CrossIcon from '@icons/24/cross.svg';

const CatalogPopupTitle: React.FC<CatalogPopupTitleProps> = ({
    title,
    setTitle,
    setResetToFirstLevel,
    closeHandler,
}) => {
    const handleBackClick = () => {
        setTitle('Каталог товаров');
        setResetToFirstLevel(true);
    };

    return (
        <div
            css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}
        >
            <div
                css={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                {title !== 'Каталог товаров' && (
                    <ArrowLeftIcon
                        css={{
                            cursor: 'pointer',
                            marginRight: scale(2),
                            flexShrink: 0,
                            width: '24px',
                            height: '24px',
                            '& path': {
                                fill: colors.textLight,
                            },
                        }}
                        onClick={handleBackClick}
                    />
                )}
                <span
                    css={{
                        fontSize: '1.5rem',
                        lineHeight: '1.5',
                    }}
                >
                    {title}
                </span>
            </div>
            <button
                onClick={closeHandler}
                css={{
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                    padding: 0,
                    width: '24px',
                    height: '24px',
                    '& svg': {
                        width: '24px',
                        height: '24px',
                        '& path': {
                            fill: colors.textLight,
                        },
                    },
                }}
            >
                <CrossIcon />
            </button>
        </div>
    );
};

export default CatalogPopupTitle;
